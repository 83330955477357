import styled from "styled-components";
import { motion, easeOut, useInView } from "framer-motion";
import { useRef } from "react";
import {
  BodyParagraph,
  ProjectSubheader,
  Colors,
  GlassStyle,
  boxShadow,
} from "./SharedStyles";

import Image1 from "./assets/plus-flyin/1.png";
import Image2 from "./assets/plus-flyin/2.png";
import Image3 from "./assets/plus-flyin/3.png";
import Image4 from "./assets/plus-flyin/4.png";
import Image5 from "./assets/plus-flyin/5.png";
import Image6 from "./assets/plus-flyin/6.png";
import Image7 from "./assets/plus-flyin/7.png";
import Image8 from "./assets/plus-flyin/8.png";
import Image9 from "./assets/plus-flyin/9.png";
import Image10 from "./assets/plus-flyin/10.png";
import Image11 from "./assets/plus-flyin/11.png";
import Image12 from "./assets/plus-flyin/12.png";
import Image13 from "./assets/plus-flyin/13.png";
import Image14 from "./assets/plus-flyin/14.png";
import Image15 from "./assets/plus-flyin/15.png";
import PlusDesignSystemFallback from "./assets/plus-design-system-fallback.png";

const Wrapper = styled.div`
  width: 100%;
  width: 1024px;
  height: 540px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Caption = styled(motion.div)`
  width: 300px;
  height: 300px;
  border-radius: 6px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  ${GlassStyle}
`;

const PlusDesignSystemMobile = styled.img`
  width: 100%;
  height: auto;
  display: none;
`;

const Image = styled(motion.img)`
  position: absolute;
  border-radius: 6px;
  ${boxShadow}
`;

const ImageTransition = {
  type: "spring",
  stiffness: 500,
  damping: 15,
  opacity: { easeOut, duration: 0.35 },
};

const HoverImageTransition = {
  delay: 0,
  ...ImageTransition,
};

const GradientParagraph = styled(BodyParagraph)`
  color: ${Colors.Text};
`;

const PlusDesignSystem = () => {
  const wrapperRef = useRef(null);
  const wrapperIsInView = useInView(wrapperRef, { amount: 0.75, once: true });
  return (
    <Wrapper ref={wrapperRef}>
      <Image
        src={Image1}
        style={{ width: 266, height: 177, left: 0, top: 0 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0, ...ImageTransition }}
      />
      <Image
        src={Image2}
        style={{ width: 266, height: 48, left: 0, top: 189 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0, ...ImageTransition }}
      />
      <Image
        src={Image3}
        style={{ width: 266, height: 48, left: 0, top: 249 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0, ...ImageTransition }}
      />
      <Image
        src={Image4}
        style={{ width: 266, height: 171, left: 0, top: 309 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0, ...ImageTransition }}
      />
      <Image
        src={Image5}
        style={{ width: 486, height: 48, left: 0, top: 492 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.1, ...ImageTransition }}
      />
      <Image
        src={Image6}
        style={{ width: 384, height: 48, left: 278, top: 0 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.1, ...ImageTransition }}
      />
      <Image
        src={Image7}
        style={{ width: 275, height: 48, left: 278, top: 60 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.1, ...ImageTransition }}
      />
      <Image
        src={Image8}
        style={{ width: 72, height: 360, left: 278, top: 120 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.1, ...ImageTransition }}
      />
      <Image
        src={Image9}
        style={{ width: 402, height: 48, left: 362, top: 432 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.2, ...ImageTransition }}
      />
      <Image
        src={Image10}
        style={{ width: 266, height: 48, left: 498, top: 492 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.2, ...ImageTransition }}
      />
      <Image
        src={Image11}
        style={{ width: 350, height: 48, left: 674, top: 0 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.4, ...ImageTransition }}
      />
      <Image
        src={Image12}
        style={{ width: 459, height: 48, left: 565, top: 60 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.3, ...ImageTransition }}
      />
      <Image
        src={Image13}
        style={{ width: 350, height: 240, left: 674, top: 120 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.4, ...ImageTransition }}
      />
      <Image
        src={Image14}
        style={{ width: 90, height: 48, left: 674, top: 372 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.3, ...ImageTransition }}
      />
      <Image
        src={Image15}
        style={{ width: 248, height: 168, left: 776, top: 372 }}
        animate={
          wrapperIsInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0.95 }
        }
        transition={{ delay: 0.3, ...ImageTransition }}
      />
      <Caption>
        <PlusDesignSystemMobile src={PlusDesignSystemFallback} alt="" />
        <ProjectSubheader>End to end design</ProjectSubheader>
        <GradientParagraph>
          I was responsible for the visual and interaction design of the
          complete Plus product. My aim was to develop a modular, legible visual
          language.
        </GradientParagraph>
      </Caption>
    </Wrapper>
  );
};

export default PlusDesignSystem;
