import styled from "styled-components";
import { motion, easeOut } from "framer-motion";
import {
  BodyParagraph,
  Caption,
  Lockup,
  LockupReverse,
  ProjectSubheader,
  boxShadow,
  moduleGap,
  BigLogo,
  borderRadius,
  ScreenSize,
} from "./SharedStyles";

import FB5 from "./assets/fb5.mp4";
import VideoPlayerControls from "./assets/fb_video_controls.png";
import Bookmarks from "./Bookmarks";
import { forwardRef } from "react";

const Wrapper = styled.div`
  width: 100%;
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${moduleGap}
  position: relative;
  @media (max-width: ${ScreenSize.MobileMax}) {
    display: none;
  }
`;

const VideoWrapper = styled.div`
  ${boxShadow}
  position: relative;
  width: 616px;
  height: 346px;
  ${borderRadius}
  overflow: hidden;
  flex-shrink: 0;
`;

const Facebook = forwardRef((props, ref) => {
  return (
    <Wrapper ref={ref}>
      <Lockup>
        <BigLogo
          style={{ fontSize: 140 }}
          initial={{ opacity: 0, translateX: -20 }}
          animate={
            props.facebookInView
              ? { opacity: 1, translateX: 0 }
              : { opacity: 0, translateX: -20 }
          }
          transition={{ easeOut, duration: 0.35 }}
        >
          Facebook
        </BigLogo>
        <Caption
          initial={{ opacity: 0, translateX: 20 }}
          animate={
            props.facebookInView
              ? { opacity: 1, translateX: 0 }
              : { opacity: 0, translateX: 20 }
          }
          transition={{ easeOut, duration: 0.35 }}
        >
          <ProjectSubheader>Product designer, 2014&ndash;2017</ProjectSubheader>
          <BodyParagraph>
            I worked on the Product Distribution and Video teams. I built a
            reputation for shipping difficult projects thanks to my rigorous
            design process.
          </BodyParagraph>
        </Caption>
      </Lockup>
      <Bookmarks />

      <LockupReverse
        animate={props.freelanceInView ? { opacity: 0 } : { opacity: 1 }}
        transition={{ easeOut, duration: 0.35 }}
      >
        <VideoWrapper>
          <img
            src={VideoPlayerControls}
            alt=""
            style={{
              width: 616,
              height: 346,
              position: "relative",
              zIndex: 100,
              borderRadius: 24,
            }}
          />
          <video
            src={FB5}
            autoPlay
            muted
            loop
            style={{
              width: 616,
              height: 346,
              position: "absolute",
              top: 0,
              left: 0,
              borderRadius: 24,
            }}
          />
        </VideoWrapper>
        <Caption>
          <ProjectSubheader>Videos team</ProjectSubheader>
          <BodyParagraph>
            I designed core interfaces and interactions for Facebook's video
            presence on every platform. Some notable projects were the video
            players, Channels, picture-in-picture video, 360 degree video, and
            Oculus Rift video playback interfaces.
          </BodyParagraph>
        </Caption>
      </LockupReverse>
    </Wrapper>
  );
});

export default Facebook;
