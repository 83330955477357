import {
  BodyParagraph,
  DarkGlassStyle,
  DarkCaption,
  Lockup,
  LockupReverse,
  BigLogo,
  ProjectSubheader,
  borderRadius,
  Colors,
  moduleGap,
  fontFamily,
  ScreenSize,
} from "./SharedStyles";
import styled from "styled-components";
import { motion, useInView, easeOut } from "framer-motion";
import { useRef } from "react";
import FrameWireframes from "./FrameWireframes";
import { forwardRef } from "react";
import HygieneImage from "./assets/frame-hygiene.png";

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 96px 0;
  position: relative;

  @media (max-width: ${ScreenSize.MobileMax}) {
    display: none;
  }
`;

const Container = styled(motion.div)`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${moduleGap}
`;

const DarkSubheader = styled(ProjectSubheader)`
  color: ${Colors.DarkHeader};
`;

const DarkParagraph = styled(BodyParagraph)`
  color: ${Colors.DarkParagraph};
`;

const Caption = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${DarkGlassStyle}
  padding: 32px;
  ${borderRadius}
  flex: 1;
  min-height: 100%;
  align-self: stretch;
`;

const HygieneImageWrapper = styled.div`
  width: 616px;
  height: 337px;
  ${DarkGlassStyle}
  ${borderRadius}
`;

const HygieneImageContainer = styled.img`
  width: 100%;
`;

const Frame = forwardRef((props, ref) => {
  const heroRef = useRef(null);
  const heroIsInView = useInView(heroRef, { amount: 0.6, once: true });
  const slackRef = useRef(null);
  const slackIsInView = useInView(slackRef, {
    amount: 0,
    once: false,
    margin: "100px",
  });

  return (
    <div ref={ref}>
      <Wrapper>
        <Container>
          <Lockup style={{ padding: "0 0 0" }}>
            <BigLogo
              style={{ color: "rgba(255,255,255,0.8)", textShadow: "none" }}
              initial={{ opacity: 0, translateX: -20 }}
              animate={
                props.frameInView
                  ? { opacity: 1, translateX: 0 }
                  : { opacity: 0, translateX: -20 }
              }
              transition={{ easeOut, duration: 0.35 }}
            >
              Frame.io
            </BigLogo>
            <DarkCaption
              initial={{ opacity: 0, translateX: 20 }}
              animate={
                props.frameInView
                  ? { opacity: 1, translateX: 0 }
                  : { opacity: 0, translateX: 20 }
              }
              transition={{ easeOut, duration: 0.35 }}
            >
              <ProjectSubheader
                initial={{ color: Colors.Header }}
                animate={
                  props.frameInView
                    ? { color: Colors.DarkHeader }
                    : { color: Colors.Header }
                }
                transition={{ easeOut, duration: 0.35 }}
              >
                Lead product designer, 2020&ndash;2021
              </ProjectSubheader>
              <BodyParagraph
                initial={{ color: Colors.Header }}
                animate={
                  props.frameInView
                    ? { color: Colors.DarkParagraph }
                    : { color: Colors.Header }
                }
                transition={{ easeOut, duration: 0.35 }}
              >
                As the most senior design IC in the company, I was responsible
                for framing ambiguous projects, building stakeholder consensus,
                and executing on our most complex product features.
              </BodyParagraph>
            </DarkCaption>
          </Lockup>
          <Lockup>
            <Caption>
              <DarkSubheader>Design process and hygiene</DarkSubheader>
              <DarkParagraph>
                Leadership asked for my help in making the team's design process
                more rigorous. I formalized the use of video presentations,
                created standards around file organization, wrote documentation,
                and created our standards around engineering specs.
              </DarkParagraph>
            </Caption>
            <HygieneImageWrapper>
              <HygieneImageContainer src={HygieneImage} alt="" />
            </HygieneImageWrapper>
          </Lockup>
          <LockupReverse
            ref={slackRef}
            animate={props.facebookInView ? { opacity: 0 } : { opacity: 1 }}
            transition={{ easeOut, duration: 0.35 }}
          >
            <Caption>
              <DarkSubheader>#requests-for-joe</DarkSubheader>
              <DarkParagraph>
                My job was to translate high level strategy for the product into
                concrete ideas that teams could execute on. At some point, every
                team&rsquo;s work made its way across my desk.
              </DarkParagraph>
              <DarkParagraph>I was very popular on Slack.</DarkParagraph>
            </Caption>
            <FrameWireframes inView={slackIsInView} />
          </LockupReverse>
        </Container>
      </Wrapper>
    </div>
  );
});

export default Frame;
