import styled from "styled-components";
import { motion, easeOut } from "framer-motion";
import { ExternalLink } from "react-feather";
import { fontFamily, GradientText, Colors } from "./SharedStyles";

const Link = styled(motion.a)`
  background: linear-gradient(
    180deg,
    ${Colors.BrandC} 0%,
    ${Colors.BrandD} 100%
  );
  border-radius: 16px;
  outline: none;
  border: none;
  font-size: 18px;
  line-height: 24px;
  ${fontFamily}
  padding: 8px 16px;
  color: #000;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  margin-top: 8px;
`;

const Gradient = styled.span`
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  ${fontFamily.Body}
  color: #000;
  transition: all 0.35s ease-out;
  width: 100%;
`;

const LinkButton = ({ href, label, mobile }) => {
  return (
    <Link
      href={href}
      style={mobile ? { width: "fit-content", marginTop: 0 } : null}
      initial={{
        opacity: 0,
        scale: 0.99,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      whileHover={{
        opacity: 0.9,
        scale: 1.03,
      }}
      whileTap={{ scale: 0.99, opacity: 0.9 }}
      transition={{
        type: "spring",
        stiffness: 350,
        damping: 20,
        opacity: { easeOut, duration: 0.25 },
      }}
    >
      <Gradient>
        <ExternalLink size="16" />
        {label}
      </Gradient>
    </Link>
  );
};

export default LinkButton;
