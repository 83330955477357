import { motion, easeOut, useInView } from "framer-motion";
import PlusTeam from "./assets/plusteam.jpeg";
import PlusDesignSystem from "./PlusDesignSystem";

import { useRef } from "react";
import styled from "styled-components";
import {
  BigLogo,
  BodyParagraph,
  Caption,
  ProjectHeader,
  ProjectSubheader,
  borderRadius,
  Lockup,
  LockupReverse,
  GlassStyle,
  moduleGap,
  ScreenSize,
} from "./SharedStyles";
import LinkButton from "./LinkButton";

import PlusCaptureProcess from "./PlusCaptureProcess";
import Notecard from "./Notecard.js";
import { forwardRef } from "react";

const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 96px;
  position: relative;
  z-index: 1000;
  @media (max-width: ${ScreenSize.MobileMax}) {
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  ${moduleGap}
`;

const TeamPhoto = styled(motion.img)`
  display: block;
  width: 616px;
  height: 380px;
  ${borderRadius}
  box-shadow: 0 0.5px 10px rgba(0, 0, 0, 0.2);
`;

const Plus = forwardRef((props, ref) => {
  return (
    <Wrapper ref={ref}>
      <Container>
        <Lockup>
          <BigLogo
            style={{ fontSize: 132 }}
            animate={
              props.plusInView
                ? { color: "rgba(0,0,0,0.7)" }
                : {
                    color: "rgba(255,255,255,0.7)",
                  }
            }
            transition={{ easeOut, duration: 0.35 }}
          >
            Plus Docs
          </BigLogo>
          <Caption>
            <ProjectSubheader>
              Principal designer, 2021&ndash;2023
            </ProjectSubheader>
            <BodyParagraph>
              I joined <a href="http://plusdocs.com">Plus</a> as the founding
              designer. I&rsquo;ve designed nearly every feature in the product,
              conducted research, hired and managed another designer,
              contributed to our front-end codebase, and more.
            </BodyParagraph>
          </Caption>
        </Lockup>
        <PlusCaptureProcess />
        <PlusDesignSystem />
        <LockupReverse
          animate={props.frameInView ? { opacity: 0 } : { opacity: 1 }}
          transition={{ easeOut, duration: 0.35 }}
        >
          <Caption
            style={{
              height: 364,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <ProjectSubheader>A little bit of everything</ProjectSubheader>
            <BodyParagraph>
              When you work at a startup, every job is your job. In my time at
              Plus, I've done everything I can to help the product, company, and
              team succeed.
            </BodyParagraph>
          </Caption>
          <Notecard />
        </LockupReverse>
      </Container>
    </Wrapper>
  );
});

export default Plus;
