import styled from "styled-components";
import { motion } from "framer-motion";

// Screen sizes

export const ScreenSize = {
  MobileMax: "959px",
};

// Hex codes

export const Colors = {
  Header: "#222223",
  Paragraph: "#3B3B3D",
  BrandA: "#7AA7FF",
  BrandB: "#ED94FF",
  BrandC: "#FF96BB",
  BrandD: "#F5CBB0",
  DarkHeader: "#fff",
  DarkParagraph: "rgba(255,255,255,0.85)",
};

// Mixins
export const fontFamily = {
  Header: "font-family: 'Barlow', sans-serif;",
  Body: "font-family: 'Anuphan', sans-serif;",
};

export const boldFont = "font-weight: 800;";

export const headerFont = `font-size: 32px; line-height: 105%; ${fontFamily.Header}`;

export const paragraphFont = `font-size: 16px; line-height: 140%; ${fontFamily.Body}`;

export const subheaderFont = `font-size: 16px; line-height: 105%; ${fontFamily.Body}`;

export const boxShadow = "box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.1);";

export const borderRadius = "border-radius: 24px;";

export const BackgroundBlur = "backdrop-filter: blur(29px);";

export const moduleGap = "gap: 48px;";

export const GlassStyle = `
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.5) 100%); 
  border: 1px solid rgba(255,255,255,0.5); 
  ${boxShadow}
`;

export const DarkGlassStyle =
  "background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 100%); border: 1px solid rgba(255,255,255,0.1); box-shadow: 0px 1.5px 10px rgba(0, 0, 0, 0.1);";

export const GradientText = `
  background: linear-gradient(180deg, ${Colors.BrandD} 0%, ${Colors.BrandB} 66.42%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  `;

export const HeaderGradientText = `
  background: linear-gradient(180deg, ${Colors.BrandA} 0%, ${Colors.BrandB} 66.42%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  `;

// Components

export const BigLogo = styled(motion.h2)`
  ${fontFamily.Header}
  font-size: 152px;
  display: block;
  width: 616px;
  line-height: 100%;
  flex-shrink: 0;
  font-weight: 800 !important;
  font-kerning: normal;
  letter-spacing: -3px;
  color: rgba(0, 0, 0, 0.7);
`;

export const BodyParagraph = styled(motion.p)`
  ${fontFamily}
  ${paragraphFont}
  color: ${Colors.Paragraph};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  & a {
    color: ${Colors.BrandB};
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px dotted ${Colors.BrandB};
  }
`;

export const ProjectHeader = styled(motion.h2)`
  ${fontFamily}
  color: ${Colors.Text};
  ${headerFont}
  ${boldFont}
  color: ${Colors.Header};
  margin-bottom: 8px;
`;

export const ProjectSubheader = styled(motion.h3)`
  ${fontFamily}
  ${subheaderFont};
  color: ${Colors.Header};
  ${boldFont}
  margin-bottom: 8px;
`;

export const LogoHeader = styled(motion.div)`
  ${GlassStyle}
  width: 1024px;
  padding: 32px;
  ${borderRadius}
  ${boxShadow}
  display: flex;
  flex-direction: row;
  gap: 48px;
`;

export const Caption = styled(motion.div)`
  ${GlassStyle}
  ${borderRadius}
  width: 100%;
  align-self: stretch;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DarkCaption = styled(Caption)`
  ${DarkGlassStyle}
`;

export const Lockup = styled(motion.div)`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: row;
  gap: 48px;
  justify-content: center;
  align-items: center;
`;

export const LockupReverse = styled(Lockup)`
  flex-direction: row-reverse;
`;

export const PresentationCaption = styled(motion.div)``;
