import styled from "styled-components";
import { motion, easeOut } from "framer-motion";
import { useState, useEffect } from "react";

import { DarkGlassStyle, boxShadow } from "./SharedStyles";

const Wrapper = styled(motion.div)`
  width: 616px;
  height: 420px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  ${DarkGlassStyle}
`;

const Message = styled(motion.div)`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-family: "Lato", sans-serif;
  width: fit-content;
`;

const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  font-size: 24px;
  line-height: 32px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: left;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
`;

const Name = styled.p`
  width: 100%;
  margin-bottom: 4px;
  opacity: 0.3;
`;

const Content = styled.p`
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  background: #5b53ff;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding-left: 16px;
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow: hidden;
  padding: 16px;
  justify-content: end;
`;

const Composer = styled.div`
  width: 100%;
  padding: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const FakeInput = styled.div`
  border-radius: 6px;
  height: 32px;
  width: 100%;
  border: 1px solid #5b53ff;
  padding: 6px 6px;
`;

const TrafficLight = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;

const Sidebar = styled.div`
  width: 144px;
  padding: 16px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

const SelectedChannel = styled.div`
  width: 100%;
  height: 16px;
  border-radius: 3px;
  background: #5b53ff;
`;

const Channel = styled(SelectedChannel)`
  background: rgba(255, 255, 255, 0.1);
`;

const FrameWireframes = ({ inView }) => {
  const messages = [
    {
      name: "CEO",
      avatar: "🗂️",
      time: "9:14 AM",
      message: "Review today at 3PM.",
    },
    {
      name: "Core PM",
      avatar: "🔎",
      time: "10:38 AM",
      message: "Let's talk about the video player today!",
    },
    {
      name: "Growth PM",
      avatar: "📈",
      time: "11:04 AM",
      message: "Could you look over these notifications briefs?",
    },
    {
      name: "Enterprise PM",
      avatar: "🔒",
      time: "1:38 PM",
      message: "Any ideas for a single sign-on flow?",
    },
    {
      name: "Senior Eng",
      avatar: "🍽️",
      time: "2:16 PM",
      message: "Hey, got some time to talk about this spec?",
    },
    {
      name: "Customer expert",
      avatar: "📸",
      time: "3:46 PM",
      message: "Just recorded a great interview, wanna see?",
    },
    {
      name: "PM",
      avatar: "🏁",
      time: "4:56 PM",
      message: "Can I attend design review tomorrow?",
    },
    {
      name: "Enterprise PM",
      avatar: "📤",
      time: "5:31 PM",
      message: "Share designs look good!",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(1);
  const [messageList, setMessageList] = useState([messages[0], messages[1]]);

  const cycleMessages = () => {
    if (inView) {
      let newIndex = currentIndex;
      let newMessageList = [...messageList];

      if (newIndex >= messages.length - 1) {
        newIndex = 0;
      } else {
        newIndex++;
      }

      newMessageList.push(messages[newIndex]);
      setMessageList(newMessageList);
      setCurrentIndex(newIndex);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(cycleMessages, 2000);
    return () => clearInterval(intervalId);
  }, [currentIndex, inView]);

  return (
    <Wrapper>
      <Header>
        <TrafficLight style={{ backgroundColor: "#ED6A5D" }} />
        <TrafficLight style={{ backgroundColor: "#F4BF4F" }} />
        <TrafficLight style={{ backgroundColor: "#61C554" }} />
      </Header>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          flex: 1,
        }}
      >
        <Sidebar>
          <Channel style={{ width: 108 }} />
          <Channel style={{ width: 56 }} />
          <Channel style={{ width: 72 }} />
          <Channel style={{ width: 44 }} />
          <SelectedChannel />
          <Channel style={{ width: 96 }} />
          <Channel style={{ width: 108 }} />
          <Channel style={{ width: 56 }} />
          <Channel style={{ width: 72 }} />
          <Channel style={{ width: 44 }} />
          <Channel style={{ width: 96 }} />
          <Channel style={{ width: 108 }} />
          <Channel style={{ width: 56 }} />
        </Sidebar>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <MessageList>
            {messageList.map((message, index) => {
              return (
                <Message
                  key={index}
                  initial={{ opacity: 0, translateY: 30 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  transition={{
                    opacity: { easeOut, duration: 0.25 },
                    translateY: { type: "spring", stiffness: 200, damping: 20 },
                  }}
                >
                  <Avatar>{message.avatar}</Avatar>
                  <Text>
                    <Name>{message.name}</Name>
                    <Content>{message.message}</Content>
                  </Text>
                </Message>
              );
            })}
          </MessageList>
          <Composer>
            <FakeInput>
              <motion.div
                style={{ width: 2, height: "100%", backgroundColor: "#5b53ff" }}
                animate={{ opacity: [0, 0, 1] }}
                transition={{
                  type: "linear",
                  times: [0, 0.75, 0.75],
                  repeat: Infinity,
                  repeatDelay: 0.75,
                }}
              />
            </FakeInput>
          </Composer>
        </div>
      </div>
    </Wrapper>
  );
};

export default FrameWireframes;
