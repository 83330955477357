import styled from "styled-components";
import {
  GlassStyle,
  borderRadius,
  boxShadow,
  Colors,
  fontFamily,
  boldFont,
  headerFont,
} from "./SharedStyles";
import { useState, useEffect } from "react";
import { motion, easeOut, AnimatePresence } from "framer-motion";

const Wrapper = styled.div`
  width: 616px;
  height: 364px;
  position: relative;
  flex-shrink: 0;
`;

const Note = styled(motion.div)`
  width: 616px;
  height: 364px;
  ${borderRadius}
  padding: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  ${headerFont}
  ${fontFamily.Header}
  color: ${Colors.Header};
  ${boldFont}
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  ${GlassStyle}
`;

const Emoji = styled(motion.div)`
  font-size: 64px;
  line-height: 64px;
  margin-bottom: 16px;
`;

const Notecard = () => {
  const emojis = ["👨‍🎨", "💻", "🔍", "🤝", "🎄", "🎉", "🛠️", "🐞", "🎥", "🌟"];
  const strings = [
    "Hired and managed another product designer.",
    "Frequently contributed to our frontend codebase.",
    "Designed and conducted user research interviews.",
    "Defined our design & engineering collaboration process.",
    "Built a Figma plugin for a holiday party game.",
    "Defined our company rules around social activities.",
    "Interviewed engineering candidates.",
    "Hours and hours of issue writing and quality assurance.",
    "325 Loom videos of design reviews, bugs, and pull requests.",
    "Shaped our product through northstar feature explorations.",
  ];

  const [stringIndex, setStringIndex] = useState(0);

  const cycleStringIndex = () => {
    let newStringIndex = stringIndex;
    if (newStringIndex >= strings.length - 1) {
      newStringIndex = 0;
    } else {
      newStringIndex++;
    }
    setStringIndex(newStringIndex);
  };

  useEffect(() => {
    const intervalId = setInterval(cycleStringIndex, 4000);
    return () => clearInterval(intervalId);
  }, [stringIndex]);

  return (
    <Wrapper>
      <AnimatePresence mode={"wait"}>
        <Note
          key={stringIndex}
          initial={{ opacity: 0, translateY: 10 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{
            opacity: { easeOut, duration: 0.25 },
            translateY: { type: "spring", stiffness: 200, damping: 20 },
            scale: { easeOut, duration: 0.25 },
          }}
        >
          <Emoji
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              delay: 0.125,
              opacity: { easeOut, duration: 0.5 },
              scale: { type: "spring", stiffness: 200, damping: 20 },
            }}
          >
            {emojis[stringIndex]}
          </Emoji>
          {strings[stringIndex]}
        </Note>
      </AnimatePresence>
    </Wrapper>
  );
};

export default Notecard;
