import styled from "styled-components";
import { motion, easeOut } from "framer-motion";
import {
  BackgroundBlur,
  GlassStyle,
  GradientText,
  boldFont,
  borderRadius,
  boxShadow,
  fontFamily,
  headerFont,
  Colors,
  ScreenSize,
} from "./SharedStyles";
import { forwardRef } from "react";
import CopyEmailButton from "./CopyEmailButton";

const MediumHeader = styled.h3`
  ${headerFont}
  ${fontFamily}
  ${boldFont}
  padding-bottom: 8px;
  color: ${Colors.BrandC};
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 96px 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  pointer-events: none;
  @media (max-width: ${ScreenSize.MobileMax}) {
    display: none;
  }
`;

const Container = styled(motion.div)`
  width: 480px;
  padding: 72px;
  ${borderRadius}
  ${boxShadow}
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1000;
  ${GlassStyle}
  ${BackgroundBlur}
`;

const Footer = forwardRef((props, ref) => {
  return (
    <Wrapper ref={ref}>
      {/* <Container
        initial={{ opacity: 0, scale: 0.9 }}
        animate={
          props.footerInView
            ? { opacity: 1, scale: 1 }
            : { opacity: 0, scale: 0 }
        }
        transition={{
          easeOut,
          duration: 0.35,
        }}
      >

        <CopyEmailButton mobile />
      </Container> */}
    </Wrapper>
  );
});

export default Footer;
