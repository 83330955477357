import styled from "styled-components";
import {
  GlassStyle,
  fontFamily,
  Colors,
  borderRadius,
  Link,
  BackgroundBlur,
  headerFont,
  boldFont,
  subheaderFont,
  paragraphFont,
  moduleGap,
  ScreenSize,
} from "./SharedStyles";
import { ExternalLink } from "react-feather";
import CopyEmailButton from "./CopyEmailButton";
import LinkButton from "./LinkButton";
import { motion } from "framer-motion";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
${moduleGap}
  align-items: center;
  justify-content: center;
  padding: 16px;
  display: none;

  @media (max-width: ${ScreenSize.MobileMax}) {
    display: flex;
    position: relative;
`;

// const Gradient = styled.div`
//   width: 100vw;
//   height: 100vh;
//   background: linear-gradient(119.36deg, #ff649b 0%, #f59e67 100%);
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1;
// `;

const Caption = styled.div`
  ${fontFamily}
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  color: ${Colors.Header};
`;

const MobileHeader = styled.div`
  ${headerFont}
  ${boldFont}
  padding-bottom: 4px;
  margin-bottom: 4px;
  color: ${Colors.Header};
`;

const GradientHeader = styled(MobileHeader)`
  //   background: linear-gradient(90deg, #211cfa 0%, #ff90aa 66.67%);
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   background-clip: text;
  //   text-fill-color: transparent;
`;

const MobileSubheader = styled.h3`
  ${subheaderFont}
  ${boldFont}
  color: ${Colors.Header};
  margin-bottom: 8px;
`;

const MobileParagraph = styled.p`
  ${paragraphFont}
  color: ${Colors.Paragraph};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BigEmoji = styled(motion.div)`
  font-size: 96px;
  transform-origin: 0 0;
`;

const MobileFallback = () => {
  return (
    <Wrapper>
      <Caption>
        <BigEmoji
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ type: "spring", stiffness: 500, damping: 10 }}
        >
          📵
        </BigEmoji>
        <GradientHeader style={{ marginBottom: 16 }}>
          You're viewing the mobile version of my portfolio site
        </GradientHeader>
        <MobileParagraph>
          Thanks for visiting my portfolio! The mobile version of this site is
          mostly just my CV. Please switch to a larger resolution to see work
          examples and miniature case studies.
        </MobileParagraph>
        <CopyEmailButton mobile />
      </Caption>
      <Caption>
        <MobileHeader>Plus</MobileHeader>
        <MobileSubheader>
          Principal product designer, 2021&ndash;2023
        </MobileSubheader>
        <MobileParagraph>
          I joined Plus as the founding designer and employee #1. I've spent the
          past two years building our product, team, and company from the ground
          up. I've had an impact on nearly every area of the business, including
          recruiting, culture, engineering, research, and more.
        </MobileParagraph>
        <LinkButton href="http://plusdocs.com" label="Plusdocs.com" mobile />
      </Caption>
      <Caption>
        <MobileHeader>Frame.io</MobileHeader>
        <MobileSubheader>
          Lead product designer, 2020&ndash;2021
        </MobileSubheader>
        <MobileParagraph>
          As the most senior design IC in the company, I was responsible for
          framing work for other designers and product teams, building
          stakeholder consensus for senior leadership, and executing on our most
          complex product features.
        </MobileParagraph>
      </Caption>
      <Caption>
        <MobileHeader>Consulting</MobileHeader>
        <MobileSubheader>2017&ndash;2019</MobileSubheader>
        <MobileParagraph>
          I took a few years off after Facebook to consult and travel. I worked
          with companies like Artsy and The Browser Company to prototype
          features.
        </MobileParagraph>
      </Caption>
      <Caption>
        <MobileHeader>Facebook</MobileHeader>
        <MobileSubheader>Product designer, 2014&ndash;2017</MobileSubheader>
        <MobileParagraph>
          I worked on the Product Distribution and Video teams. I built a
          reputation for shipping difficult projects through my use of thorough
          auditing, cross-functional collaboration, and presentations.
        </MobileParagraph>
      </Caption>
      <Caption>
        <MobileHeader>Vimeo</MobileHeader>
        <MobileSubheader>Senior designer, 2012&ndash;2014</MobileSubheader>
        <MobileParagraph>
          I redesigned the video player, the homepage, and did a lot of branding
          and illustration work.
        </MobileParagraph>
      </Caption>
      <Caption>
        <GradientHeader style={{ marginBottom: 8 }}>
          Let's work together
        </GradientHeader>
        <MobileParagraph>
          I'm currently looking for senior IC roles at exciting technology
          companies. Feel free to get in touch if you think we'd be a good
          match.
        </MobileParagraph>
        <CopyEmailButton mobile />
      </Caption>
      {/* <Gradient /> */}
    </Wrapper>
  );
};

export default MobileFallback;
