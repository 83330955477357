import Plus from "./Plus.js";
import Frame from "./Frame.js";
import Freelance from "./Freelance.js";
import Facebook from "./Facebook.js";
import Footer from "./Footer.js";
import styled from "styled-components";
import TypographicHero from "./TypographicHero.js";
import Background from "./Background.js";
import MobileFallback from "./MobileFallback.js";

import { useInView } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import { ScreenSize } from "./SharedStyles.js";

const Content = styled.div`
  padding-top: 100vh;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: ${ScreenSize.MobileMax}) {
    padding-top: 0;
  }
`;

function App() {
  const [plusIsScrolled, setPlusIsScrolled] = useState(false);
  const [currentView, setCurrentView] = useState("hero");
  const [scrollPos, setScrollPos] = useState(0);
  const scrollRef = useRef();
  const iHaveAJob = false;

  const heroRef = useRef(null);
  const plusRef = useRef(null);
  const frameRef = useRef(null);
  const fbRef = useRef(null);
  const freelanceRef = useRef(null);
  const footerRef = useRef(null);

  const margin = "0px 0px -300px 0px";

  const plusInView = useInView(plusRef, {
    amount: 0,
    margin: "0px 0px -150px 0px",
  });
  const frameInView = useInView(frameRef, {
    amount: 0,
    margin: margin,
  });
  const fbInView = useInView(fbRef, { amount: 0, margin: margin });
  const freelanceInView = useInView(freelanceRef, {
    amount: 0,
    margin: margin,
  });
  const footerInView = useInView(footerRef, {
    amount: 0,
    margin: "0px 0px -300px 0px",
  });

  const getIsPlusScrolled = () => {
    let newPlusIsScrolled;
    if (plusInView && scrollPos > 1) {
      newPlusIsScrolled = true;
    } else {
      newPlusIsScrolled = false;
    }
    setPlusIsScrolled(newPlusIsScrolled);
  };

  const getView = () => {
    let newView;
    if (footerInView) {
      newView = "footer";
    } else if (freelanceInView) {
      newView = "freelance";
    } else if (fbInView) {
      newView = "fb";
    } else if (frameInView) {
      newView = "frame";
    } else if (plusIsScrolled) {
      newView = "plus";
    } else {
      newView = "hero";
    }
    setCurrentView(newView);
  };

  useEffect(() => {
    getIsPlusScrolled();
    getView();
    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      {!iHaveAJob && (
        <Content ref={scrollRef}>
          <Background currentView={currentView} frameInView={frameInView} />
          <Plus
            ref={plusRef}
            plusInView={plusIsScrolled}
            frameInView={frameInView}
          />
          <Frame
            ref={frameRef}
            frameInView={frameInView}
            facebookInView={fbInView}
          />
          <Facebook
            ref={fbRef}
            facebookInView={fbInView}
            freelanceInView={freelanceInView}
          />
          <Freelance
            ref={freelanceRef}
            footerInView={footerInView}
            freelanceInView={freelanceInView}
          />
          <Footer ref={footerRef} footerInView={footerInView} />
          <MobileFallback />
        </Content>
      )}
      {iHaveAJob && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 32,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: 24,
            textAlign: "center",
          }}
        >
          <h1>Thanks for your interest in my portfolio.</h1>{" "}
          <p>
            I'm not currently looking for product design work. Please check out
            some of my side projects.
          </p>
          <a href="https://www.figma.com/community/widget/1161482789310866273/Lore-for-Figma">
            Lore for Figma, a Figma plugin for roleplaying games
          </a>
          <a href="https://yophorncrow.itch.io/the-gorgon-pit">
            The Gorgon Pit, an adventure for Cy_Borg
          </a>
          <a href="http://infinite-blood-war.vercel.app">
            Infinite Blood War, a random RPG generator with content sourced from
            ChatGPT
          </a>
          <p>
            Thanks, <br />
            Joe
          </p>
        </div>
      )}
    </>
  );
}

export default App;
