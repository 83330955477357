import styled from "styled-components";
import { easeOut, motion, AnimatePresence } from "framer-motion";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useState } from "react";
import {
  GlassStyle,
  borderRadius,
  fontFamily,
  Colors,
  headerFont,
  boldFont,
  paragraphFont,
  boxShadow,
} from "./SharedStyles";
import PlusCaptureGraphic from "./PlusCaptureGraphic";

const Wrapper = styled(motion.div)`
  width: 100%;
  max-width: 1024px;
  height: 576px;
  position: relative;
  ${GlassStyle}
  ${borderRadius}
`;

const SlideWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 576px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  padding-left: 64px;
`;

const Caption = styled(motion.div)`
  ${fontFamily}
  color: ${Colors.Text};
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
`;

const PresentationHeader = styled.h3`
  ${headerFont}
  ${boldFont}
  color: ${Colors.Header};
  margin-bottom: 8px;
`;

const PresentationText = styled.p`
  ${paragraphFont};
  color: ${Colors.Paragraph};
`;

const SlideButton = styled(motion.button)`
  background: #0400e5;
  width: 24px;
  height: 24px;
  color: #fff;
  border-radius: 24px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
`;

const Progress = styled.div`
  position: absolute;
  max-width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direciton: row;
  gap: 8px;
  left: 0;
  bottom: 12px;
  width: 100%;

  & span {
    border-radius: 4px;
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.2);
  }

  & span.active {
    background: #0400e5;
  }
`;

const SlideButtonVariants = {
  initial: {
    scale: 0,
    opacity: 0,
    transition: {
      scale: { type: "spring", stiffness: 200, damping: 20 },
      opacity: { easeOut, duration: 0.25 },
    },
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      scale: { type: "spring", stiffness: 200, damping: 20 },
      opacity: { easeOut, duration: 0.25 },
    },
  },
  whileHover: {
    scale: 1.15,
    transition: { type: "spring", stiffness: 200, damping: 20 },
  },
  whileTap: {
    scale: 0.95,
    transition: { type: "spring", stiffness: 200, damping: 20 },
  },
};

const CaptionVariants = {
  initial: {
    opacity: 0,
    translateX: 100,
  },
  animate: {
    opacity: 1,
    translateX: 0,
  },
  exit: { opacity: 0, translateX: -100 },
  transition: {
    delay: 0.1,
    easeOut,
    duration: 0.5,
  },
};

const SlideOne = () => {
  return (
    <Caption
      variants={CaptionVariants}
      initial={"initial"}
      animate={"animate"}
      transition={"transition"}
      exit={"exit"}
    >
      <PresentationHeader>Designing Plus</PresentationHeader>
      <PresentationText>
        Plus is a technically complex product with a lot of surface area. We've
        built a web app, browser extension, embeds, emails, and plugins into
        multiple apps like Google Docs, Notion, and Slack.
      </PresentationText>
    </Caption>
  );
};

const SlideTwo = () => {
  return (
    <Caption
      variants={CaptionVariants}
      initial={"initial"}
      animate={"animate"}
      transition={"transition"}
      exit={"exit"}
    >
      <PresentationHeader>Capture</PresentationHeader>
      <PresentationText>
        My goal was to make the capture process feel magic and natural. In
        addition to traditional click-and-drag screenshotting, we&rsquo;ve built
        a smart capture mode that captures Snapshots with a single click.
      </PresentationText>
    </Caption>
  );
};

const SlideThree = () => {
  return (
    <Caption
      variants={CaptionVariants}
      initial={"initial"}
      animate={"animate"}
      transition={"transition"}
      exit={"exit"}
    >
      <PresentationHeader>Action recording</PresentationHeader>
      <PresentationText>
        To make Snapshots even more powerful, we created actionr recording. It
        allows a user to record keypresses and clicks that will be replayed when
        Plus recaptures their Snapshot.
      </PresentationText>
    </Caption>
  );
};

const SlideFour = () => {
  return (
    <Caption
      variants={CaptionVariants}
      initial={"initial"}
      animate={"animate"}
      transition={"transition"}
      exit={"exit"}
    >
      <PresentationHeader>Repair</PresentationHeader>
      <PresentationText>
        We designed a quick, simple process for repairing Snapshots that become
        logged out. We spent a lot of time investing in user education and
        copywriting to make every aspect of the process clear and
        understandable.
      </PresentationText>
    </Caption>
  );
};

const SlideFive = () => {
  return (
    <Caption
      variants={CaptionVariants}
      initial={"initial"}
      animate={"animate"}
      transition={"transition"}
      exit={"exit"}
    >
      <PresentationHeader>&hellip;and more!</PresentationHeader>
      <PresentationText>
        For two years, I was responsible for designing nearly every feature for
        a complex, enterprise-ready product. In the end, we've made something
        that I'm very proud of.
      </PresentationText>
    </Caption>
  );
};

const PlusCaptureProcess = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const Slides = [
    <SlideOne key="intro" />,
    <SlideTwo key="capture" />,
    <SlideThree key="recording" />,
    <SlideFour key="repair" />,
    <SlideFive key="end" />,
  ];
  const changeSlideIndex = (change) => {
    let newSlideIndex = slideIndex;
    newSlideIndex = newSlideIndex + change;
    setSlideIndex(newSlideIndex);
  };
  return (
    <Wrapper>
      <SlideWrapper>
        <AnimatePresence mode="wait">{Slides[slideIndex]}</AnimatePresence>
        <PlusCaptureGraphic slideIndex={slideIndex} />
        <Progress key="progressIndicator" style={{ width: "100%" }}>
          <SlideButton
            initial={"initial"}
            exit={"initial"}
            animate={slideIndex === 0 ? "initial" : "animate"}
            whileHover={"whileHover"}
            whileTap={"whileTap"}
            variants={SlideButtonVariants}
            key={"leftSlideButton"}
            style={{
              marginRight: 8,
            }}
            disabled={slideIndex === 0 ? true : false}
            onClick={() => {
              changeSlideIndex(-1);
            }}
          >
            <ArrowLeft size={16}></ArrowLeft>
          </SlideButton>
          {Array(Slides.length)
            .fill(null)
            .map((_, index) => {
              return (
                <span
                  key={index}
                  className={index === slideIndex ? "active" : null}
                ></span>
              );
            })}
          <SlideButton
            initial={"initial"}
            exit={"initial"}
            animate={slideIndex >= Slides.length - 1 ? "initial" : "animate"}
            whileHover={"whileHover"}
            whileTap={"whileTap"}
            variants={SlideButtonVariants}
            key={"slideButtonRight"}
            style={{
              marginLeft: 8,
            }}
            disabled={slideIndex >= Slides.length - 1 ? true : false}
            onClick={() => {
              changeSlideIndex(1);
            }}
          >
            <ArrowRight size={16}></ArrowRight>
          </SlideButton>
        </Progress>
      </SlideWrapper>
    </Wrapper>
  );
};

export default PlusCaptureProcess;
