import styled, { keyframes } from "styled-components";
import { useState } from "react";
import { motion, easeOut } from "framer-motion";
import {
  BigLogo,
  fontFamily,
  HeaderGradientText,
  ScreenSize,
  Colors,
  paragraphFont,
} from "./SharedStyles";
import { forwardRef } from "react";
import CopyEmailButton from "./CopyEmailButton";
import { ChevronDown } from "react-feather";
import NameImage from "./assets/name.svg";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  @media (max-width: ${ScreenSize.MobileMax}) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 0;
  position: absolute;
  text-align: left;
  bottom: 48px;
  left: 48px;
`;

const Header = styled(motion.h1)`
  ${fontFamily.Header}
  color: #fff;
  font-size: 152px;
  line-height: 100%;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  margin-bottom: 16px;
`;

const Subheader = styled(motion.h2)`
  ${fontFamily.Body}
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  z-index: 0;
  text-align: left;
  color: ${Colors.BrandB};
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #fff;
  max-width: 640px;
`;

const CopyEmailWrapper = styled.div`
  position: absolute;
  top: 48px;
  right: 48px;
`;

const ScrollIndicator = styled(motion.div)`
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  ${fontFamily.Body}
  ${paragraphFont}
  color: #fff;
  width: 200px;
  margin-left: -100px;
  left: 50%;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const ScrollTransition = {
  type: "spring",
  easeOut,
  duration: 1,
  repeat: Infinity,
  repeatDelay: 3,
};

const Name = styled(motion.img)``;

const TypographicHero = forwardRef((props, ref) => {
  const [copied, setCopied] = useState(false);
  return (
    <Wrapper ref={ref}>
      {/* <Container
        initial={{ opacity: 0, translateY: -20 }}
        animate={
          props.plusInView
            ? {
                opacity: 0,
                translateY: -20,
                transition: { delay: 0, easeOut, duration: 0.35 },
              }
            : {
                opacity: 1,
                translateY: 0,
                transition: { delay: 0.5, easeOut, duration: 0.7 },
              }
        }
      >
        <CopyEmailWrapper>
          <CopyEmailButton />
        </CopyEmailWrapper>
        <TextWrapper>
          <Header>Joe Lifrieri</Header>
          <Subheader>
            I'm a senior IC product design generalist. I specialize in systems
            thinking and framing ambiguous problems.
          </Subheader>
        </TextWrapper>
      </Container> */}
      <ScrollIndicator
        initial={{ opacity: 0 }}
        animate={
          props.plusInView
            ? {
                opacity: 0,
                transition: { delay: 0, easeOut, duration: 0.35 },
              }
            : {
                opacity: 1,
                transition: { delay: 0.5, easeOut, duration: 0.7 },
              }
        }
      >
        <motion.span
          initial={{ y: 0 }}
          animate={{
            y: -16,
          }}
          transition={{
            delay: 0.05,
            ...ScrollTransition,
          }}
        >
          Scroll
        </motion.span>
        <motion.span
          initial={{ y: 0 }}
          animate={{
            y: -16,
          }}
          transition={{
            ...ScrollTransition,
          }}
        >
          <ChevronDown />
        </motion.span>
      </ScrollIndicator>
    </Wrapper>
  );
});

export default TypographicHero;
