import styled from "styled-components";
import { motion, easeOut } from "framer-motion";
import { useState } from "react";
import {
  GlassStyle,
  borderRadius,
  fontFamily,
  Colors,
  headerFont,
  boldFont,
  paragraphFont,
  boxShadow,
} from "./SharedStyles";
import { ArrowLeft, ArrowRight } from "react-feather";
import BM1 from "./assets/bookmarks/bm1.png";
import BM2 from "./assets/bookmarks/bm2.png";
import BM3 from "./assets/bookmarks/bm3.png";
import BM4 from "./assets/bookmarks/bm4.mp4";
import BM5 from "./assets/bookmarks/bm5.png";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled(motion.div)`
  width: 100%;
  max-width: 1024px;
  height: 576px;
  ${borderRadius}
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  position: relative;
  ${GlassStyle}
`;

const Caption = styled(motion.div)`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PresentationHeader = styled.h3`
  ${headerFont}
  ${boldFont}
  color: ${Colors.Header};
  margin-bottom: 8px;
`;

const PresentationText = styled.p`
  ${paragraphFont}
  color: ${Colors.Paragraph}
`;

const Slide = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 48px;
  padding: 0 64px;
  align-items: center;
`;

const SlideButton = styled(motion.button)`
  background: #1877f2;
  width: 24px;
  height: 24px;
  color: #fff;
  border-radius: 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5px 10px #1877f2;
  cursor: pointer;
  ${boxShadow}
`;

const Progress = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direciton: row;
  gap: 8px;
  bottom: 12px;

  & span {
    border-radius: 4px;
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, 1);
    background: rgba(0, 0, 0, 0.2);
  }

  & span.active {
    background: #1877f2;
  }
`;

const Graphic = styled(motion.div)`
  width: 546px;
  height: 100%;
  position: relative;
`;

const Image = styled(motion.img)`
  border-radius: 4px;
  box-shadow: 0 0.5px 6px rgba(0, 0, 0, 0.2);
`;

const Video = styled(motion.video)`
  border-radius: 4px;
  box-shadow: 0 0.5px 6px rgba(0, 0, 0, 0.2);
`;

const SlideButtonVariants = {
  initial: {
    scale: 0,
    opacity: 0,
    transition: {
      scale: { type: "spring", stiffness: 200, damping: 20 },
      opacity: { easeOut, duration: 0.25 },
    },
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      scale: { type: "spring", stiffness: 200, damping: 20 },
      opacity: { easeOut, duration: 0.25 },
    },
  },
  whileHover: {
    scale: 1.15,
    transition: { type: "spring", stiffness: 200, damping: 20 },
  },
  whileTap: {
    scale: 0.95,
    transition: { type: "spring", stiffness: 200, damping: 20 },
  },
};

const CaptionVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: { opacity: 0 },
  transition: {
    delay: 0.1,
    easeOut,
    duration: 0.5,
  },
};

const SlideVariants = {
  initial: {
    translateX: 100,
    opacity: 1,
    transition: {
      easeOut,
      duration: 0.35,
    },
  },
  animate: {
    translateX: 0,
    opacity: 1,
    transition: {
      easeOut,
      duration: 0.35,
    },
  },
  exit: {
    translateX: -100,
    opacity: 0,
    transition: {
      easeOut,
      duration: 0.35,
    },
  },
};

const Intro = () => {
  return (
    <Slide
      variants={SlideVariants}
      initial={"initial"}
      animate={"animate"}
      exit={"exit"}
    >
      <Caption
        variants={CaptionVariants}
        initial={"initial"}
        animate={"animate"}
        exit={"exit"}
        transition={CaptionVariants.transition}
      >
        <PresentationHeader>Bookmarks</PresentationHeader>
        <PresentationText>
          In 2015, I redesigned Bookmarks: the links on the left side of News
          Feed. My job was to figure out how the surface worked, document it,
          and streamline the content.
        </PresentationText>
      </Caption>
      <Graphic
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={BM1}
          style={{ width: 546 }}
          alt=""
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            delay: 0.4,
            opacity: { easeOut, duration: 0.25 },
            scale: { type: "spring", stiffness: 200, damping: 20 },
          }}
        />
      </Graphic>
    </Slide>
  );
};

const Audit = () => {
  return (
    <Slide
      variants={SlideVariants}
      initial={"initial"}
      animate={"animate"}
      exit={"exit"}
    >
      <Caption
        variants={CaptionVariants}
        initial={"initial"}
        animate={"animate"}
        exit={"exit"}
        transition={CaptionVariants.transition}
      >
        <PresentationHeader>Auditing</PresentationHeader>
        <PresentationText>
          The first step was to build an inventory of all existing bookmarks. I
          interviewed every product team who owned a link that could appear in
          Bookmarks. I built a spreadsheet of every link, what it did, who it
          showed up for, and more.
        </PresentationText>
      </Caption>
      <Graphic
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={BM2}
          style={{ width: 546 }}
          alt=""
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            delay: 0.4,
            opacity: { easeOut, duration: 0.25 },
            scale: { type: "spring", stiffness: 200, damping: 20 },
          }}
        />
      </Graphic>
    </Slide>
  );
};

const Research = () => {
  return (
    <Slide
      variants={SlideVariants}
      initial={"initial"}
      animate={"animate"}
      exit={"exit"}
    >
      <Caption
        variants={CaptionVariants}
        initial={"initial"}
        animate={"animate"}
        exit={"exit"}
        transition={CaptionVariants.transition}
      >
        <PresentationHeader>Research</PresentationHeader>
        <PresentationText>
          Through user research, we discovered that the most relevant, useful
          bookmarks took you to entities like Groups, Pages, Events, and
          profiles. Our approach became centered around getting people to their
          favorite entities.
        </PresentationText>
      </Caption>
      <Graphic
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={BM3}
          style={{ width: 546 }}
          alt=""
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            delay: 0.4,
            opacity: { easeOut, duration: 0.25 },
            scale: { type: "spring", stiffness: 200, damping: 20 },
          }}
        />
      </Graphic>
    </Slide>
  );
};

const Organization = () => {
  return (
    <Slide
      variants={SlideVariants}
      initial={"initial"}
      animate={"animate"}
      exit={"exit"}
    >
      <Caption
        variants={CaptionVariants}
        initial={"initial"}
        animate={"animate"}
        exit={"exit"}
        transition={CaptionVariants.transition}
      >
        <PresentationHeader>Organization</PresentationHeader>
        <PresentationText>
          We reorganized the content around principles we&rsquo;d uncovered.
          We&rsquo;d reduce the number of total sections in the column, group
          bookmarks together based on type, and elevate shortcuts to entities
          like Pages and Groups above links to features. We&rsquo;d use
          truncation to keep sections from becoming too long.
        </PresentationText>
      </Caption>
      <Graphic
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Video
          src={BM4}
          style={{ width: 546 }}
          alt=""
          autoPlay
          muted
          loop
          controls
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            delay: 0.4,
            opacity: { easeOut, duration: 0.25 },
            scale: { type: "spring", stiffness: 200, damping: 20 },
          }}
        />
      </Graphic>
    </Slide>
  );
};

const Result = () => {
  return (
    <Slide
      variants={SlideVariants}
      initial={"initial"}
      animate={"animate"}
      exit={"exit"}
    >
      <Caption
        variants={CaptionVariants}
        initial={"initial"}
        animate={"animate"}
        exit={"exit"}
        transition={CaptionVariants.transition}
      >
        <PresentationHeader>Result</PresentationHeader>
        <PresentationText>
          Facebook went from showing 50 links per user to about 20. We increased
          participation on the surface by 9% and brought the strategy to mobile
          later in that year. Our team successfully shipped the first change to
          this surface in over nine years.
        </PresentationText>
      </Caption>
      <Graphic
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={BM5}
          style={{ width: 546 }}
          alt=""
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            delay: 0.4,
            opacity: { easeOut, duration: 0.25 },
            scale: { type: "spring", stiffness: 200, damping: 20 },
          }}
        />
      </Graphic>
    </Slide>
  );
};

const Bookmarks = () => {
  const Slides = [
    <Intro />,
    <Audit />,
    <Research />,
    <Organization />,
    <Result />,
  ];
  const [slideIndex, setSlideIndex] = useState(0);

  const changeSlideIndex = (change) => {
    let newSlideIndex = slideIndex;
    newSlideIndex = newSlideIndex + change;
    setSlideIndex(newSlideIndex);
  };

  return (
    <Wrapper>
      <Container>
        {Slides[slideIndex]}

        <Progress>
          <SlideButton
            initial={"initial"}
            exit={"initial"}
            animate={slideIndex === 0 ? "initial" : "animate"}
            whileHover={"whileHover"}
            whileTap={"whileTap"}
            variants={SlideButtonVariants}
            style={{
              marginRight: 8,
            }}
            disabled={slideIndex === 0 ? true : false}
            onClick={() => {
              changeSlideIndex(-1);
            }}
          >
            <ArrowLeft size={16}></ArrowLeft>
          </SlideButton>
          {Array(Slides.length)
            .fill(null)
            .map((_, index) => {
              return (
                <span
                  key={index}
                  className={index === slideIndex ? "active" : null}
                ></span>
              );
            })}
          <SlideButton
            initial={"initial"}
            exit={"initial"}
            animate={slideIndex >= Slides.length - 1 ? "initial" : "animate"}
            whileHover={"whileHover"}
            whileTap={"whileTap"}
            variants={SlideButtonVariants}
            style={{
              marginLeft: 8,
            }}
            disabled={slideIndex >= Slides.length - 1 ? true : false}
            onClick={() => {
              changeSlideIndex(1);
            }}
          >
            <ArrowRight size={16}></ArrowRight>
          </SlideButton>
        </Progress>
      </Container>
    </Wrapper>
  );
};

export default Bookmarks;
