import styled from "styled-components";
import { ScreenSize, fontFamily, paragraphFont } from "./SharedStyles";
import { motion, easeOut } from "framer-motion";
import NameImage from "./assets/name.svg";
import { ChevronDown } from "react-feather";
import CopyEmailButton from "./CopyEmailButton";

const Colors = {
  Portfolio: {
    A: "#7AA7FF",
    B: "#ED94FF",
    C: "#FF96BB",
    D: "#F5CBB0",
  },
  Plus: {
    A: "#AB52D4",
    B: "#0400E5",
    C: "#FF794F",
    D: "#FFD90F",
  },
  Frame: {
    A: "#5B53FF",
    B: "#D83284",
    C: "#8837EF",
    D: "#FF6057",
  },
  Facebook: {
    A: "#1877F2",
    B: "#EBA365",
    C: "#88EBD5",
    D: "#E27380",
  },
  Freelance: {},
};

const opacityValue = 0.35;

const CircleAVariants = {
  initial: {
    translateX: 7,
    translateY: -207,
    background: "#000",
    opacity: 0,
    scale: 0,
  },
  hero: {
    translateX: 7,
    translateY: -207,
    background: "#000",
    opacity: 1,
    scale: 1,
  },
  plus: {
    translateX: 100,
    translateY: 100,
    backgroundColor: Colors.Plus.A,
    opacity: 0.6,
    scale: 5,
  },
  frame: {
    translateX: 400,
    translateY: 200,
    backgroundColor: Colors.Frame.A,
    opacity: 0.8,
    scale: 12,
  },
  fb: {
    translateX: -300,
    translateY: 400,
    backgroundColor: Colors.Facebook.A,
    opacity: 0.8,
    scale: 14,
  },
  freelance: {
    translateX: 100,
    translateY: 100,
    backgroundColor: Colors.Plus.A,
    opacity: 0.6,
    scale: 5,
  },
  footer: {
    translateX: 7,
    translateY: -150,
    background: "#000",
    opacity: 1,
    scale: 1,
  },
};

const CircleBVariants = {
  initial: {
    translateX: -261,
    translateY: -95,
    background: `linear-gradient(180deg, ${Colors.Portfolio.A} 0%, ${Colors.Portfolio.B} 100%)`,
    opacity: 0,
    scale: 0,
  },
  hero: {
    translateX: -261,
    translateY: -95,
    background: `linear-gradient(180deg, ${Colors.Portfolio.A} 0%, ${Colors.Portfolio.B} 100%)`,
    opacity: 1,
    scale: 1,
  },
  plus: {
    translateX: -100,
    translateY: -100,
    backgroundColor: Colors.Plus.B,
    opacity: 0.7,
    scale: 8,
  },
  frame: {
    translateX: -200,
    translateY: 100,
    backgroundColor: Colors.Frame.B,
    opacity: 1,
    scale: 7,
  },
  fb: {
    translateX: 150,
    translateY: 100,
    backgroundColor: Colors.Frame.B,
    opacity: 1,
    scale: 12,
  },
  freelance: {
    translateX: -100,
    translateY: -100,
    backgroundColor: Colors.Plus.B,
    opacity: 0.7,
    scale: 8,
  },
  footer: {
    translateX: -261,
    translateY: -35,
    background: `linear-gradient(180deg, ${Colors.Portfolio.A} 0%, ${Colors.Portfolio.B} 100%)`,
    opacity: 1,
    scale: 1,
  },
};

const CircleCVariants = {
  initial: {
    translateX: 67,
    translateY: -95,
    background: `linear-gradient(180deg, ${Colors.Portfolio.B} 0%, ${Colors.Portfolio.C} 100%)`,
    opacity: 0,
    scale: 0,
  },
  hero: {
    translateX: 67,
    translateY: -95,
    background: `linear-gradient(180deg, ${Colors.Portfolio.B} 0%, ${Colors.Portfolio.C} 100%)`,
    opacity: 1,
    scale: 1,
  },
  plus: {
    translateX: -100,
    translateY: 130,
    backgroundColor: Colors.Plus.C,
    opacity: 0.8,
    scale: 6,
  },
  frame: {
    translateX: -50,
    translateY: -100,
    backgroundColor: Colors.Frame.C,
    opacity: 0.7,
    scale: 5,
  },
  fb: {
    translateX: -100,
    translateY: -100,
    backgroundColor: Colors.Frame.C,
    opacity: 0.8,
    scale: 7,
  },
  freelance: {
    translateX: -100,
    translateY: 130,
    backgroundColor: Colors.Plus.C,
    opacity: 0.8,
    scale: 6,
  },
  footer: {
    translateX: 67,
    translateY: -35,
    background: `linear-gradient(180deg, ${Colors.Portfolio.B} 0%, ${Colors.Portfolio.C} 100%)`,
    opacity: 1,
    scale: 1,
  },
};

const CircleDVariants = {
  initial: {
    translateX: 439,
    translateY: -95,
    background: `linear-gradient(180deg, ${Colors.Portfolio.C} 0%, ${Colors.Portfolio.D} 100%)`,
    opacity: 0,
    scale: 0,
  },
  hero: {
    translateX: 439,
    translateY: -95,
    background: `linear-gradient(180deg, ${Colors.Portfolio.C} 0%, ${Colors.Portfolio.D} 100%)`,
    opacity: 1,
    scale: 1,
  },
  plus: {
    translateX: 200,
    translateY: -50,
    backgroundColor: Colors.Plus.D,
    opacity: 1,
    scale: 4,
  },
  frame: {
    translateX: 400,
    translateY: -200,
    backgroundColor: Colors.Frame.D,
    opacity: 0.8,
    scale: 8,
  },
  fb: {
    translateX: -250,
    translateY: -100,
    backgroundColor: Colors.Frame.D,
    opacity: 1,
    scale: 6,
  },
  freelance: {
    translateX: 200,
    translateY: -50,
    backgroundColor: Colors.Plus.D,
    opacity: 1,
    scale: 4,
  },
  footer: {
    translateX: 439,
    translateY: -35,
    background: `linear-gradient(180deg, ${Colors.Portfolio.C} 0%, ${Colors.Portfolio.D} 100%)`,
    opacity: 1,
    scale: 1,
  },
};

const SubheaderCopy = {
  hero: `I\u2019m a senior IC product designer specializing in systems thinking and ambiguous problems.`,
  footer: `Let\u2019s work together.`,
};

const Wrapper = styled(motion.div)`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  @media (max-width: ${ScreenSize.MobileMax}) {
    display: none;
  }
`;

const BlurWrapper = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  top: 0;
  left: 0;
`;

const Circle = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-left: -28px;
  margin-top: -28px;
`;

const Name = styled(motion.img)`
  width: 937px;
  height: 456px;
`;

const Subheader = styled(motion.h2)`
  color: #fff;
  ${fontFamily.Body};
  font-size: 24px;
  line-height: 130%;
  width: 560px;
  font-weight: 500;
  text-align: center;
`;

const CircleA = styled(Circle)`
  z-index: 1;
`;

const CircleB = styled(Circle)`
  z-index: 2;
`;

const CircleC = styled(Circle)`
  z-index: 3;
`;

const CircleD = styled(Circle)`
  z-index: 4;
`;

const ScrollIndicator = styled(motion.div)`
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  ${fontFamily.Body}
  ${paragraphFont}
  color: #fff;
  width: 200px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const ScrollTransition = {
  ease: "easeOut",
  duration: 1,
  repeat: Infinity,
  repeatType: "mirror",
};

const Background = ({ frameInView, currentView }) => {
  return (
    <Wrapper
      initial={{ background: "#000" }}
      animate={
        currentView === "frame" ||
        currentView === "hero" ||
        currentView === "footer"
          ? { background: "#000" }
          : { background: "#fff" }
      }
      transition={{ ease: "linear", duration: 0.25 }}
    >
      <BlurWrapper
        initial={{ filter: "blur(0)" }}
        animate={
          currentView === "hero" || currentView === "footer"
            ? {
                filter: "blur(0px)",
                transition: { ease: "linear", duration: 0 },
              }
            : {
                filter: "blur(60px",
                transition: { ease: "linear", duration: 0.5 },
              }
        }
      >
        <CircleA
          variants={CircleAVariants}
          initial={"initial"}
          animate={currentView}
          transition={{
            type: "spring",
            stiffness: 250,
            damping: 20,
            opacity: { easeOut, duration: 0.35 },
          }}
        />
        <CircleB
          variants={CircleBVariants}
          initial={"initial"}
          animate={currentView}
          transition={{
            type: "spring",
            stiffness: 250,
            damping: 20,
            opacity: { easeOut, duration: 0.35 },
          }}
        />
        <CircleC
          variants={CircleCVariants}
          initial={"initial"}
          animate={currentView}
          transition={{
            type: "spring",
            stiffness: 250,
            damping: 20,
            opacity: { easeOut, duration: 0.35 },
          }}
        />
        <CircleD
          variants={CircleDVariants}
          initial={"initial"}
          animate={currentView}
          transition={{
            type: "spring",
            stiffness: 250,
            damping: 20,
            opacity: { easeOut, duration: 0.35 },
          }}
        />

        <Name
          src={NameImage}
          animate={
            currentView === "hero" || currentView === "footer"
              ? { opacity: 1 }
              : { opacity: 0 }
          }
          transition={{ easeOut, duration: 0.35 }}
        />
        <Subheader
          animate={
            currentView === "hero" || currentView === "footer"
              ? { opacity: 1 }
              : { opacity: 0 }
          }
          transition={{ easeOut, duration: 0.35 }}
        >
          {currentView === "footer" ? SubheaderCopy.footer : SubheaderCopy.hero}
        </Subheader>
        {currentView === "hero" || currentView === "footer" ? (
          <CopyEmailButton />
        ) : null}
        {currentView === "hero" && (
          <ScrollIndicator
            initial={{ opacity: 0 }}
            animate={
              currentView !== "hero"
                ? {
                    opacity: 0,
                    transition: { delay: 0, easeOut, duration: 0.35 },
                  }
                : {
                    opacity: 1,
                    transition: { delay: 0.5, easeOut, duration: 0.7 },
                  }
            }
          >
            <motion.span
              animate={{
                translateY: 14,
              }}
              transition={{
                delay: 0.05,
                ...ScrollTransition,
              }}
            >
              Scroll
            </motion.span>
            <motion.span
              animate={{
                y: 8,
              }}
              transition={{
                ...ScrollTransition,
              }}
            >
              <ChevronDown />
            </motion.span>
          </ScrollIndicator>
        )}
      </BlurWrapper>
    </Wrapper>
  );
};

export default Background;
