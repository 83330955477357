import styled from "styled-components";
import {
  BigLogo,
  BodyParagraph,
  Caption,
  Lockup,
  ProjectHeader,
  ProjectSubheader,
  moduleGap,
  LockupReverse,
  GlassStyle,
  borderRadius,
  Colors,
  ScreenSize,
} from "./SharedStyles";
import ArtsyVideo from "./assets/artsy.mp4";
import TBCVideo from "./assets/tbc.mov";
import LoreVideo from "./assets/lore.mp4";
import { motion, easeOut } from "framer-motion";
import { forwardRef } from "react";
import LinkButton from "./LinkButton";

const Wrapper = styled.div`
  width: 100%;
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  ${moduleGap}
  position: relative;
  align-items: center;
  @media (max-width: ${ScreenSize.MobileMax}) {
    display: none;
  }
`;

const Freelance = forwardRef((props, ref) => {
  return (
    <Wrapper ref={ref}>
      <Lockup>
        <BigLogo
          style={{ fontSize: 140 }}
          initial={{ opacity: 0, translateY: 20 }}
          animate={
            props.freelanceInView
              ? { opacity: 1, translateY: 0 }
              : { opacity: 0, translateY: 20 }
          }
          transition={{ easeOut, duration: 0.35 }}
        >
          Freelance
        </BigLogo>
      </Lockup>
      <Lockup>
        <Caption
          style={{
            width: 316,
            alignSelf: "center",
          }}
        >
          <ProjectHeader>Artsy</ProjectHeader>
          <ProjectSubheader>Freelance, 2018</ProjectSubheader>
          <BodyParagraph>
            After Facebook, I freelanced for a few years. One of my first
            clients was Artsy. I worked with them to design and prototype their
            City Guide, an iOS app feature for finding art galleries and
            museums.
          </BodyParagraph>
        </Caption>
        <motion.div
          style={{
            background: "black",
            padding: "8px",
            borderRadius: 32,
            boxShadow: "0 0.5px 10px rgba(0,0,0,0.3)",
          }}
          viewport={{ once: true, margin: "-150px" }}
        >
          <video
            width={300}
            height={650}
            style={{ borderRadius: 24 }}
            src={ArtsyVideo}
            type="video/mp4"
            autoPlay
            muted
            loop
          >
            <source src={ArtsyVideo} type="video/mp4" />
            Your browser doesn't support this type of video.
          </video>
        </motion.div>
      </Lockup>
      <LockupReverse
        animate={props.footerInView ? { opacity: 0 } : { opacity: 1 }}
        transition={{ easeOut, duration: 0.35 }}
      >
        <Caption
          style={{
            width: 316,
            alignSelf: "center",
          }}
        >
          <ProjectHeader>Lore</ProjectHeader>
          <ProjectSubheader>Self</ProjectSubheader>
          <BodyParagraph>
            Lore is a a streamlined roleplaying game tool that lets you track
            dice rolls, player stats, inventory, and abilities. I used it weekly
            for about five years and have had thousands of players between our
            standalone and Figma apps.
          </BodyParagraph>
        </Caption>
        <motion.div
          style={{
            borderRadius: 32,
            boxShadow: "0 0.5px 10px rgba(0,0,0,0.3)",
            overflow: "hidden",
            padding: " 8px",
            background: "black",
          }}
        >
          <video
            width={300}
            height={533}
            src={LoreVideo}
            type="video/mov"
            muted
            loop
            autoPlay
            style={{ borderRadius: 24 }}
          >
            <source src={LoreVideo} type="video/mov" />
            Your browser doesn't support this type of video.
          </video>
        </motion.div>
      </LockupReverse>
    </Wrapper>
  );
});

export default Freelance;
