import styled from "styled-components";
import { motion, easeOut, AnimatePresence } from "framer-motion";
import { boxShadow } from "./SharedStyles";
import HeaderImage from "./assets/plus-capture/header.png";
import GraphImage from "./assets/plus-capture/graph.png";
import ExtensionImage from "./assets/plus-capture/extension.png";
import EbcIconImage from "./assets/plus-capture/ebc-icon.svg";
import BlueEbcImage from "./assets/plus-capture/blue-ebc.png";
import BlueLoginImage from "./assets/plus-capture/blue-login-image.png";
import LoginFormImage from "./assets/plus-capture/login-form.png";
import ComplexPageImage from "./assets/plus-capture/complex-page.png";
import PostCaptureImage from "./assets/plus-capture/post-capture.png";
import { MoreHorizontal } from "react-feather";

const Wrapper = styled(motion.div)`
  background: #fff;
  width: 546px;
  height: 400px;
  border-radius: 12px;
  ${boxShadow}
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  flex-shrink: 0;
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  background: url(${HeaderImage}) no-repeat;
  background-size: 100%;
`;

const Page = styled.div`
  width: 546px;
  height: 352px;
  background: #fff;
  padding: 16px;
  position: relative;
`;

const Graph = styled(motion.div)`
  width: 514px;
  height: 239px;
  background: url(${GraphImage}) no-repeat;
  background-size: 100%;
`;

const Extension = styled(motion.div)`
  width: 271px;
  height: 116px;
  background: url(${ExtensionImage}) no-repeat;
  background-size: 100%;
  position: absolute;
  top: 36px;
  right: 8px;
  ${boxShadow}
  border-radius: 6px;
  z-index: 1000;
`;

const EbcBox = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 514px;
  height: 239px;
  left: 16px;
  top: 16px;
  background: rgba(4, 0, 230, 0.05);
  border: 1px solid #0400e5;
  z-index: 900;
`;

const EbcButton = styled(motion.div)`
  width: 48px;
  height: 48px;
  background: #0400e5;
  border: 2px solid white;
  ${boxShadow}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
`;

const EbcIcon = styled(motion.img)`
  width: 24px;
  height: 24px;
`;

const RecordingIcon = styled(motion.div)`
  background: white;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    background: white;
    width: 28px;
    height: 28px;
    border: 1px solid #dd4d40;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    position: relative;
  }
`;

const RecordingDot = styled(motion.span)`
  display: block;
  background: #dd4d40;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: absolute;
  z-index: 1100;
`;

const MotionBar = styled(motion.div)`
  background: #fff;
  z-index: 1000;
  border: 1px solid #e6e6ea;
  position: absolute;
  border-radius: 6px;
  ${boxShadow}
  display: flex;
  position: absolute;
  bottom: 24px;
  left: 24px;
  width: 498px;
  height: 56px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconAndStringWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const IconPlaceholder = styled(motion.div)`
  width: 32px;
  height: 32px;
`;

const BlueEbcIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const String = styled(motion.div)`
  color: #100f19;
  font-family: "Helvetica", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  width: 252px;
`;

const FakeButton = styled(motion.div)`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #e6e6ea;
  box-shadow: 0px 0.5px 1.5px rgba(15, 15, 15, 0.15);
  transition: all 0.25s ease-out;
  color: #100f19;
  cursor: pointer;

  &:hover {
    background: #f3f4f9;
  }

  &:active {
    box-shadow: none;
    border: 1px solid #899bf5;
    background: #e6eafc;
    color: #0400e5;
  }
`;

const PrimaryButton = styled(motion.div)`
  padding: 4px 8px;
  background: #0400e6;
  box-shadow: 0px 0.5px 1.5px rgba(4, 0, 229, 0.25);
  border-radius: 6px;
  color: #fff;
  font-family: "Helvetica", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.35s ease-out;

  &:hover {
    background: #4340ff;
  }

  &:active {
    background: #0b08d2;
    box-shadow: none;
  }
`;

const RecordingBlob = styled(motion.div)`
  width: 300px;
  height: 108px;
  background: #fff;
  border-radius: 6px;
  ${boxShadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -54px;
  border: 1px solid #e6e6ea;
`;

const StringValues = [
  "Click an element to take a Snapshot",
  "Click an element to take a Snapshot",
  "Recording your mouse and keyboard",
  "Let us know when you're logged back in",
];

const LoginForm = styled(motion.div)`
  width: 200px;
  height: 168px;
  background: url(${LoginFormImage}) no-repeat;
  background-size: 100%;
  position: absolute;
  top: 68px;
  left: 173px;
`;

const ComplexPage = styled(motion.div)`
  background: url(${ComplexPageImage}) no-repeat;
  background-size: 100%;
  width: 514px;
  height: 320px;
`;

const PostCapture = styled(motion.div)`
  width: 498px;
  height: 304px;
  background: url(${PostCaptureImage}) no-repeat;
  background-size: 100%;
  position: absolute;
  top: 24px;
  left: 24px;
  border-radius: 16px;
  ${boxShadow};
`;

const PlusCaptureGraphic = ({ slideIndex }) => {
  return (
    <Wrapper>
      <AnimatePresence mode="wait">
        {slideIndex === 0 && (
          <Extension
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              opacity: { easeOut, duration: 0.125 },
            }}
          />
        )}
      </AnimatePresence>
      <Header />
      <Page>
        {slideIndex === 1 || slideIndex === 3 ? (
          <MotionBar
            key={"motionBar"}
            initial={{
              opacity: 0,
              translateY: 20,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
            }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              opacity: { easeOut, duration: 0.125 },
            }}
          >
            <IconAndStringWrapper>
              <IconPlaceholder
                initial={{ opacity: 0, translateY: -10 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  delay: 0.25,
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  opacity: { easeOut, duration: 0.125 },
                }}
              >
                <BlueEbcIcon
                  src={slideIndex === 1 ? BlueEbcImage : BlueLoginImage}
                  alt=""
                />
              </IconPlaceholder>
              <String
                initial={{ opacity: 0, translateY: -10 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  delay: 0.35,
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  opacity: { easeOut, duration: 0.125 },
                }}
              >
                {StringValues[slideIndex]}
              </String>
            </IconAndStringWrapper>
            <div style={{ display: "flex", gap: "8px" }}>
              {slideIndex === 3 && (
                <PrimaryButton
                  initial={{ opacity: 0, translateY: -10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  transition={{
                    delay: 0.45,
                    type: "spring",
                    stiffness: 300,
                    damping: 20,
                    opacity: { easeOut, duration: 0.125 },
                  }}
                >
                  I'm logged in
                </PrimaryButton>
              )}
              <FakeButton
                initial={{ opacity: 0, translateY: -10 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  delay: slideIndex === 1 ? 0.45 : 0.55,
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  opacity: { easeOut, duration: 0.125 },
                }}
              >
                <MoreHorizontal size={16} />
              </FakeButton>
            </div>
          </MotionBar>
        ) : null}

        {slideIndex === 2 && (
          <RecordingBlob
            initial={{ opacity: 0, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              opacity: { easeOut, duration: 0.35 },
            }}
            key={"recordingBlob"}
          >
            <RecordingIcon
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                delay: 0.25,
                type: "spring",
                stiffness: 300,
                damping: 20,
                opacity: { easeOut, duration: 0.35 },
              }}
            >
              <RecordingDot />
              <motion.div
                animate={{ scale: [1, 1.2, 1, 1], opacity: [1, 0, 0, 1] }}
                transition={{
                  delay: 0.25,
                  easeOut,
                  duration: 1.5,
                  repeatDelay: 1,
                  repeat: Infinity,
                }}
              />
            </RecordingIcon>
            <String
              style={{ width: "100%", textAlign: "center" }}
              initial={{ opacity: 0, translateY: -10 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{
                delay: 0.35,
                type: "spring",
                stiffness: 300,
                damping: 20,
                opacity: { easeOut, duration: 0.125 },
              }}
            >
              Recording your mouse and keyboard&hellip;
            </String>
          </RecordingBlob>
        )}
        {slideIndex === 1 && (
          <EbcBox
            key={"ebcBox"}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{
              delay: 0.3,
              type: "spring",
              stiffness: 200,
              damping: 25,
              opacity: { easeOut, duration: 0.125 },
            }}
          >
            <EbcButton
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              transition={{
                delay: 0.7,
                type: "spring",
                stiffness: 200,
                damping: 20,
                opacity: { easeOut, duration: 0.125 },
              }}
            >
              <EbcIcon src={EbcIconImage} alt="" />
            </EbcButton>
          </EbcBox>
        )}
        {slideIndex === 0 || slideIndex === 1 ? (
          <Graph
            key={"graph"}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              opacity: { easeOut, duration: 0.125 },
            }}
          />
        ) : null}
        {slideIndex === 2 ? (
          <ComplexPage
            key={"complexPage"}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              opacity: { easeOut, duration: 0.125 },
            }}
          />
        ) : null}
        {slideIndex === 3 ? (
          <LoginForm
            key={"loginForm"}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              opacity: { easeOut, duration: 0.125 },
            }}
          />
        ) : null}
        {slideIndex === 4 ? (
          <PostCapture
            key={"postCapture"}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              opacity: { easeOut, duration: 0.125 },
            }}
          />
        ) : null}
      </Page>
    </Wrapper>
  );
};

export default PlusCaptureGraphic;
