import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import { motion, easeOut } from "framer-motion";
import { CheckCircle, Copy } from "react-feather";
import styled from "styled-components";
import { fontFamily, Colors, GradientText } from "./SharedStyles";

const Button = styled(motion.button)`
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  outline: none;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 18px;
  line-height: 24px;
  ${fontFamily}
  padding: 8px 16px;
  color: #000;
  background: linear-gradient(
    180deg,
    ${Colors.BrandC} 0%,
    ${Colors.BrandD} 100%
  );
  cursor: pointer;
  display: inline-flex;
`;

const GradientMask = styled(motion.span)`
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  background-size: 100%;
  ${fontFamily.Body}
  color: #000;
  transition: all 0.35s ease-out;
  width: 100%;
`;

const CopyEmailButton = ({ mobile }) => {
  const [copied, setCopied] = useState(false);
  return (
    <CopyToClipboard
      text={"j.a.lifrieri@gmail.com"}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      }}
    >
      <Button
        layout
        animate={{
          opacity: 1,
          scale: 1,
        }}
        whileHover={{
          opacity: 0.9,
          scale: 1.03,
        }}
        whileTap={{ scale: 0.99, opacity: 0.9 }}
        transition={{
          type: "spring",
          stiffness: 350,
          damping: 20,
          opacity: { easeOut, duration: 0.25 },
        }}
        style={
          mobile
            ? {
                display: "inline-flex",
                justifyContent: "center",
                width: "fit-content",
              }
            : null
        }
      >
        <GradientMask>
          {copied ? (
            <motion.div
              initial={{ scale: 0.7 }}
              animate={{ scale: 1 }}
              style={{ width: 16, height: 16, padding: 0, marginTop: -4 }}
            >
              <CheckCircle size="16" />
            </motion.div>
          ) : (
            <Copy size="16" />
          )}
          <span>
            {copied ? "Copied to clipboard" : "j.a.lifrieri@gmail.com"}
          </span>
        </GradientMask>
      </Button>
    </CopyToClipboard>
  );
};

export default CopyEmailButton;
